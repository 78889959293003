import React from 'react'
import { graphql } from 'gatsby'
import { MdStar, MdPeople, MdBook, MdLinearScale } from 'react-icons/md'
import Layout from '../components/Layout'
import * as Hero from '../components/Hero'
import Centered from '../components/Centered'
import ProjectCard from '../components/ProjectCard'

import styles from './index.module.css'

export const query = graphql`
  query IndexPage {
    allProject {
      edges {
        node {
          title
          description
          fields {
            slug
          }
          repository {
            name
            primaryLanguage
            url
            collaborators
            forks
            issues
            pullRequests
            stargazers
            watchers
            pushedAt
          }
        }
      }
    }
    statistics {
      repos
      stargazers
      commits
      members
    }
  }
`

const IndexPage = ({ data: { allProject, statistics } }) => (
  <Layout>
    <div className={styles.component}>
      <Hero.Box className={styles.heroBox}>
        <div className={styles.heroText}>
          <Hero.Title className={styles.heroTitle}>Open Source</Hero.Title>
          <Hero.Lead className={styles.heroLead}>We share the passion for writing great code.</Hero.Lead>
        </div>
        <ul className={styles.stats}>
          <li className={styles.statsItem}>
            <span className={styles.statsLabel}>
              <MdBook /> Projects
            </span>{' '}
            {statistics.repos}
          </li>
          <li className={styles.statsItem}>
            <span className={styles.statsLabel}>
              <MdStar /> Stars
            </span>{' '}
            {statistics.stargazers.toLocaleString()}
          </li>
          <li className={styles.statsItem}>
            <span className={styles.statsLabel}>
              <MdPeople /> Members
            </span>{' '}
            {statistics.members}
          </li>
          <li className={styles.statsItem}>
            <span className={styles.statsLabel}>
              <MdLinearScale /> Commits
            </span>{' '}
            {statistics.commits.toLocaleString()}
          </li>
        </ul>
      </Hero.Box>
      <Centered>
        <div className={styles.cards}>
          {allProject.edges.map(({ node }, i) => (
            <div className={styles.card} key={i}>
              <ProjectCard data={node} />
            </div>
          ))}
        </div>
      </Centered>
    </div>
  </Layout>
)

export default IndexPage
