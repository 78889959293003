import React from 'react'
import { Link } from 'gatsby'
import { MdVisibility, MdStar, MdCallSplit } from 'react-icons/md'

import styles from './ProjectCard.module.css'

// const LANGUAGE_COLORS = {
//   PHP: '#4F5D95',
//   JavaScript: '#F1E05A',
// }

const ProjectCard = ({ data }) => (
  <Link to={data.fields.slug} className={styles.component}>
    <div
      className={styles.language}
      style={
        {
          // borderRight: '5px solid ' + LANGUAGE_COLORS[data.repository.primaryLanguage],
        }
      }
    >
      {data.repository.primaryLanguage}
    </div>
    <div className={styles.text}>
      <h2 className={styles.title}>{data.title}</h2>
      <p className={styles.description}>{data.description}</p>
    </div>
    <ul className={styles.stats}>
      <li>
        <MdVisibility /> {data.repository.watchers}
      </li>
      <li>
        <MdStar /> {data.repository.stargazers}
      </li>
      <li>
        <MdCallSplit /> {data.repository.forks}
      </li>
    </ul>
  </Link>
)

export default ProjectCard
